import React, { Fragment, Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink'
import { decodeEntities } from '../../../utils/helpers';
import { ViewMorePosts } from '../ViewMorePosts';
import RenderContent from '../../RenderContent';
import { FaChevronRight } from 'react-icons/fa';
import { Image } from '../../Image';
import './SearchResultsList.scss';

class SearchResultsList extends Component {

  render() {
    const {
      visibleItems,
      allMatchingItems,
      totalItems,
      showMorePostsHandler,
      filterItems,
      activeFilter,
    } = this.props;

    // Handle no search results
    if (!visibleItems || visibleItems.length < 1) return null;
    // Get result filters using result type (see gatsby-config.js)
    const uniqueResultFilters = new Set(allMatchingItems.map(result => result.type));
    const resultFilters = Array.from(uniqueResultFilters);
    return (
      <Fragment>
        {resultFilters && (
          <div className="result-filters">
            <div className="wrap">
              <div className="inner">
                <strong>Filter by: </strong>
                <span
                  className={`filter ${activeFilter === 0 ? 'active' : ''}`}
                  onClick={() => filterItems(0)}
                >
                  All
                </span>
                {resultFilters.map((filter, index) => {
                  return (
                    // eslint-disable-next-line
                    <span
                      key={index}
                      className={`filter ${((index + 1) === activeFilter) ? 'active' : ''}`}
                      onClick={() => filterItems(index + 1, filter)}
                    >
                      {filter}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="results">
          <div className="wrap">
            <div className="list">
            {visibleItems.map(result => {
              const { type } = result;
              const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
              const formattedDate = new Date(result.date).toLocaleDateString('en-US', dateOptions).replace(/\//g, '.');
              const resultSlug = `${result.node.path}`;
              return (
                <div className="result" key={result.slug}>
                  <div>
                    <div className="image">
                      {result.featuredImage &&
                        <GatsbyLink
                          className=""
                          to={resultSlug}
                          aria-label={decodeEntities(result.title)}
                        >
                          <Image src={result.featuredImage} className="background" />
                        </GatsbyLink>
                      }
                    </div>
                    <div className="inner">
                      <h4 className="title"><GatsbyLink to={resultSlug}>{decodeEntities(result.title)}</GatsbyLink></h4>
                      {result.excerpt && <RenderContent content={result.excerpt}/>}
                      {resultSlug && <GatsbyLink to={resultSlug} label={decodeEntities(result.title)} className="post-link"><FaChevronRight/></GatsbyLink>}
                      <div className="meta">
                        {formattedDate && <div className="date">{formattedDate}</div>}
                        {type && <div className="type">{type}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            </div>
          </div>
        </div>
        <ViewMorePosts
          currentItems={visibleItems.length}
          totalItems={totalItems}
          showMorePostsHandler={showMorePostsHandler}
        />
      </Fragment>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <SearchResultsList data={data} {...props} />}
  />
)
